<template>
  <div class="d-flex justify-content-between">
    <b-link :href="link ? $links.homepage : null" class="flex-shrink-1">
      <b-img
        fluid
        :src="favicon ? $images.favicon : $images.logo"
        alt="ReachingApp logo"
        :style="favicon ? 'max-height: 60px;' : 'filter: invert();'"
      />
    </b-link>
  </div>
</template>

<script>
export default {
  props: {
    favicon: { type: Boolean, default: false },
    link: { type: Boolean, default: true },
  },
}
</script>
