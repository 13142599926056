<template>
  <b-container fluid>
    <b-row class="flex-column flex-md-row min-vh-100">
      <b-col
        md="5"
        class="flex-grow-0 flex-md-grow-1 px-5 py-3 d-flex flex-column text-light bg-primary clip-right"
        :style="`background-image: url(${$images.registerBg});`"
      >
        <Logo favicon />
      </b-col>

      <b-col class="py-5 mx-xl-5" align-self="center">
        <b-container>
          <div class="mb-4">
            <h1>{{ $t("register.title") }}</h1>
            <div class="text-muted">{{ $t("login.subtitle") }}</div>
          </div>

          <div class="mb-5 text-primary d-flex align-items-center">
            <div class="h5 font-weight-normal mr-4 my-0">
              {{ $t("step") }} {{ stage }}/3
            </div>

            <div class="d-flex align-items-center">
              <template v-for="i in 3">
                <b-icon-check-circle-fill
                  :key="i"
                  v-if="i < stage"
                  font-scale="2"
                />
                <b-icon-check-circle
                  :key="i"
                  v-else-if="i == stage"
                  font-scale="2"
                />
                <b-icon-circle
                  :key="i"
                  v-else
                  font-scale="2"
                  class="text-black-50"
                />
                <hr :key="`hr_${i}`" v-if="i != 3" style="width: 5ch;" />
              </template>
            </div>
          </div>

          <b-overlay :show="activationLoading" v-if="stage == 1">
            <b-form
              @submit.prevent="checkActivationCode"
              class="text-terciary font-weight-semibold"
            >
              <FormField
                varname="activation_code"
                description="activation_code-description"
                v-model="activation_code"
                :errors="registerErrors"
                type="text"
                pattern="\w+"
                :title="$t('activation_code-help')"
                required
              ></FormField>

              <b-form-invalid-feedback
                :state="activationError ? false : null"
                class="mb-3"
              >
                {{ activationError }}
              </b-form-invalid-feedback>

              <b-button block type="submit" variant="primary" class="mt-4">
                {{ $t("continue") }}
              </b-button>
            </b-form>

            <div class="mt-4">
              <b-row class="text-muted mb-4">
                <b-col><hr /></b-col>
                <b-col cols="auto">{{ $t("already_member") }}</b-col>
                <b-col><hr /></b-col>
              </b-row>

              <b-button block variant="outline-primary" to="/login">
                {{ $t("login.submit") }}
              </b-button>
            </div>
          </b-overlay>

          <b-overlay :show="registerLoading" v-if="stage == 2">
            <b-form
              @submit.prevent="register"
              class="text-terciary font-weight-semibold"
            >
              <b-row>
                <b-col>
                  <FormField
                    varname="first_name"
                    v-model="first_name"
                    :errors="registerErrors"
                    type="text"
                    required
                  />
                </b-col>

                <b-col>
                  <FormField
                    varname="last_name"
                    v-model="last_name"
                    :errors="registerErrors"
                    type="text"
                    required
                  />
                </b-col>
              </b-row>

              <FormField
                varname="email"
                v-model="email"
                :errors="registerErrors"
                type="email"
                required
              />

              <b-collapse :visible="email.length > 0">
                <FormField
                  varname="email_confirmation"
                  label="repeat_email"
                  v-model="email_confirmation"
                  :errors="registerErrors"
                  type="email"
                  required
                />
              </b-collapse>

              <FormField
                varname="password"
                v-model="password"
                :errors="registerErrors"
                type="password"
                minlength="6"
                required
              />

              <b-collapse :visible="password.length > 0">
                <FormField
                  varname="password_confirmation"
                  label="repeat_password"
                  v-model="password_confirmation"
                  :errors="registerErrors"
                  type="password"
                  required
                />
              </b-collapse>

              <b-row>
                <b-col>
                  <FormField
                    tagname="b-form-select"
                    varname="birth_year"
                    v-model="birth_year"
                    :errors="registerErrors"
                    required
                  >
                    <b-form-select-option
                      v-for="year in $store.getters.years"
                      :key="year"
                      :value="`${year}`"
                    >
                      {{ year }}
                    </b-form-select-option>
                  </FormField>
                </b-col>

                <b-col>
                  <FormField
                    tagname="b-form-select"
                    varname="gender"
                    v-model="gender"
                    :errors="registerErrors"
                    required
                  >
                    <b-form-select-option
                      selected
                      disabled
                      value=""
                    ></b-form-select-option>
                    <b-form-select-option
                      v-for="gender in $store.getters.genders"
                      :value="gender.value"
                      :key="gender.value"
                    >
                      {{ $t(gender.text) }}
                    </b-form-select-option>
                  </FormField>
                </b-col>

                <b-col>
                  <FormField
                    tagname="b-form-select"
                    varname="language"
                    v-model="language"
                    :errors="registerErrors"
                    required
                  >
                    <b-form-select-option
                      selected
                      disabled
                      value=""
                    ></b-form-select-option>
                    <b-form-select-option
                      v-for="language in $store.getters.languages"
                      :value="language.code.toUpperCase()"
                      :key="language.code"
                      >{{ language.text }}</b-form-select-option
                    >
                  </FormField>
                </b-col>
              </b-row>

              <FormField
                tagname="b-form-select"
                varname="organizational_unit_id"
                label="organizational_unit"
                v-model="organizational_unit_id"
                :errors="registerErrors"
                required
                :options="[
                  { value: '', text: '', disabled: true },
                  ...organizational_units_options,
                ]"
              >
              </FormField>

              <b-form-group>
                <b-form-checkbox
                  size="lg"
                  id="marketing_consent_flg"
                  name="marketing_consent_flg"
                  v-model="marketing_consent_flg"
                  style="font-size: 1rem;"
                >
                  {{ $t("register.marketing_consent") }}
                </b-form-checkbox>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  size="lg"
                  id="accept_terms"
                  name="accept_terms"
                  v-model="accept_terms"
                  required
                  style="font-size: 1rem;"
                >
                  {{ $t("register.accept_terms") }}
                  <a href="#terms" @click.prevent v-b-modal.termsModal>
                    <u>{{ $t("register.accept_terms-linktext") }}</u>
                  </a>
                </b-form-checkbox>

                <b-modal id="termsModal" :title="$t('terms')" ok-only size="lg">
                  <b-row>
                    <!-- TODO: Get from prototype variable -->
                    <b-col>
                      Motionsplatsen AB<br />
                      Hälsingegatan 117 <br />
                      110 12 Stockholm
                    </b-col>
                    <b-col>                      
                      www.reachingapp.com<br />
                      org.nr: 556843-2123
                    </b-col>
                  </b-row>

                  <hr />
                  <b-tabs
                    content-class="pt-3"
                    class="tabs-clear"
                    justified
                    lazy
                  >
                    <b-tab
                      title="English"
                      :active="$i18n.locale == 'en'"
                      class="shadow-none"
                    >
                      <PolicyEn />
                    </b-tab>
                    <b-tab
                      title="Svenska"
                      :active="$i18n.locale == 'se'"
                      class="shadow-none"
                    >
                      <PolicySe />
                    </b-tab>
                  </b-tabs>
                </b-modal>
              </b-form-group>

              <b-form-invalid-feedback
                :state="registerError ? false : null"
                class="mb-3"
              >
                {{ registerError }}
              </b-form-invalid-feedback>

              <b-button block type="submit" variant="primary">{{
                $t("register.submit")
              }}</b-button>
            </b-form>
          </b-overlay>

          <b-overlay :show="profilingLoading" v-if="stage == 3">
            <DomainProfile
              :visible="stage == 3"
              :force-all="true"
              :dismissButton="false"
              @before="profilingLoading = true"
              @after="profilingLoading = false"
              @confirmed="$router.push('gamerules')"
            />
          </b-overlay>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BIconCheckCircle,
  BIconCircle,
  BIconCheckCircleFill,
} from "bootstrap-vue"
import axios from "axios"
import PolicyEn from "@/components/PolicyEn.vue"
import PolicySe from "@/components/PolicySe.vue"
import DomainProfile from "@/components/DomainProfile.vue"
import Logo from "@/components/Logo.vue"
import FormField from "@/components/FormField.vue"

export default {
  components: {
    BIconCheckCircle,
    BIconCircle,
    BIconCheckCircleFill,
    PolicyEn,
    PolicySe,
    DomainProfile,
    Logo,
    FormField,
  },
  data() {
    return {
      activation_code: "",
      activationError: "",
      activationLoading: false,
      registerError: "",
      registerErrors: {},
      registerLoading: false,
      profilingError: "",
      profilingLoading: false,
      organizational_units: [],
      stage: 1,

      marketing_consent_flg: true,
      accept_terms: false,
      email: "",
      email_confirmation: "",
      password: "",
      password_confirmation: "",
      first_name: "",
      last_name: "",
      gender: "",
      birth_year: "",
      language: this.$i18n.locale.toUpperCase(),
      organizational_unit_id: null,
      // admin_organizational_unit_id: 1,
    }
  },
  computed: {
    /**
     * Map to select options
     * @return {Array} */
    organizational_units_options() {
      return this.organizational_units.map(e => ({
        text: e.organizational_unit_name,
        value: e.organizational_unit_id,
      }))
    },
  },

  methods: {
    async checkActivationCode() {
      this.activationLoading = true
      this.activationError = ""
      try {
        const response = await axios.get(
          `/activationcode/${encodeURIComponent(this.activation_code)}`
        )
        this.organizational_units = response.data.data
        // Go to next step if organizational units are received
        if (this.organizational_units.length) {
          // Select the unit if it is only one
          if (this.organizational_units.length == 1) {
            this.organizational_unit_id = this.organizational_units[0].organizational_unit_id
          }
          this.stage = 2
          return true
        }
        throw Error(this.$t("invalid_response"))
      } catch ({ name, message, response }) {
        this.activationError = response.data?.message ?? `${name}: ${message}`
        return false
      } finally {
        this.activationLoading = false
      }
    },

    async register() {
      this.registerLoading = true
      this.registerError = ""
      this.registerErrors = {}

      let {
        email,
        email_confirmation,
        password,
        password_confirmation,
        first_name,
        last_name,
        gender,
        birth_year,
        language,
        organizational_unit_id,
        activation_code,
        accept_terms,
        marketing_consent_flg,
      } = this

      try {
        if (!accept_terms) {
          this.registerError = this.$t("register.accept_terms-error")
          return false
        }
        if (email !== email_confirmation) {
          this.registerError = this.$t("repeat_email-error")
          return false
        }
        if (password !== password_confirmation) {
          this.registerError = this.$t("repeat_password-error")
          return false
        }

        this.$store.dispatch("changeLanguage", language)
        await axios.post("/user", {
          email,
          email_confirmation,
          password,
          password_confirmation,
          first_name,
          last_name,
          gender,
          birth_year,
          language,
          organizational_unit_id,
          activation_code,
          marketing_consent_flg: marketing_consent_flg ? "Y" : "N",
        })
        await this.$store.dispatch("login", {
          email: this.email,
          password: this.password,
        })
        // Go to next stage on successful login
        this.$nextTick(() => {
          this.stage = 3
        })
        return true
      } catch ({ name, message, response }) {
        const { error_code, error_details, error_message } = response.data
        if (error_code && error_details && error_message) {
          // Show error on each field
          this.registerErrors = error_details
          this.registerError =
            `${error_message}:` +
            Object.values(error_details).map(val => `\n${val.join("\n")}`)
        } else {
          this.registerError = `${name}: ${message}`
        }
        return false
      } finally {
        this.registerLoading = false
      }
    },
  },
}
</script>
